<template>
  <div class="fluid" id="subLocations">
    <b-card style="width: 80%; margin: 0 auto; margin-bottom: 20px">
      <div class="row d-flex align-items-center">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6">
              <label for="" class="label-font">Search:</label>
              <b-form-group>
                <b-form-input
                  v-debounce:300ms="getSubLocations"
                  v-model="query.name"
                  type="text"
                  placeholder="Search"
                  class="search-input bg-light"
                />
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="col-md-3 p-0">
          <b-button
            v-if="$can('create', 'Position')"
            v-b-modal.modal-prevent-closing
            class="btn mt-1 mt-lg-0 add-btn d-block ml-auto"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Add New Sub Location </span>
              <span>
                <img
                  src="@/assets/images/icons/add-icon.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
    <div style="width: 80%; margin: 0 auto" class="table-responsive bg-white cursor-icon short-table rounded-lg shadow-lg p-2">
      <b-table
        :items="subLocations"
        :fields="parentFields"
        show-empty
        responsive
        striped
        hover
        class=""
        
        :per-page="query.per_page"
      >
        <template #cell(id)="row">
          <div :class="`p-1`">
            <p>
              {{ row.index + from }}
            </p>
          </div>
        </template>
        <template #cell(name)="row">
          <p>
            {{ row.item.name }}
          </p>
        </template>
        <template #cell(location_name)="row">
          <p>
            {{ row.item.location.name }}
          </p>
        </template>
        <template #cell(action)="row">
          <div>
            <button
              v-if="$can('update', 'Position')"
              v-b-modal.modal-prevent-closing
              @click.prevent="getSubLocation(row.item.id)"
              class="btn btn-info d-block ml-auto"
            >
              Edit
            </button>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-if="total > query.per_page"
        class="p-1 mt-1 d-flex justify-content-end bg-white"
        v-model="query.page"
        :total-rows="total"
        :per-page="query.per_page"
        aria-controls="my-table"
        @change="getSubLocations($event)"
        pills
        size="lg"
        page-class="info"
      ></b-pagination>
    </div>

    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Create Sub Location"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-title="subLocation.id ? 'Update' : 'Save'"
      :no-close-on-backdrop="true"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="subLocation.name"
            :state="nameState"
            required
            class="mb-2 bg-light data-text"
            placeholder="Enter Sub Location Name"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Location"
          label-for="name-input"
          invalid-feedback="Location is required"
          :state="locationState"
        >
          <v-select
            id="country"
            v-model="subLocation.location"
            :options="locations"
            label="name"
            placeholder="Select Location"
          >
          </v-select>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
BButton,
BCard,
BCol,
BFormGroup,
BFormInput,
BPagination,
BRow,
BTable,
} from "bootstrap-vue";

import { toast } from "@/utils/toast";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
  },

  data() {
    return {
      homeLink: false,
      subLocations: [],
      nameState: null,
      locationState: null,
      subLocation: {
        name: "",
        location: null,
        location_id: null,
        status: 0,
      },
      total: 0,
      from: 0,
      query: {
        name: "",
        page: 1,
        per_page: 20,
      },
      locations: [],
      parentFields: [
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
          label: "name",
        },
        {
          key: "location_name",
          sortable: true,
          label: "Location Name",
        },
        {
          key: "action",
          label: "action",
        },
      ],
    };
  },
  created() {
    this.getSubLocations();
    this.getLocations();
  },
  methods: {
    getSubLocations(e) {
      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }
      this.$store
        .dispatch("subLocation/getSubLocations", this.query)
        .then((response) => {
          this.subLocations = response.data.data;
          this.total = response.data.total;
          this.from = response.data.from;
        });
    },
    getLocations() {
      this.$store
        .dispatch("location/getAllLocations")
        .then((response) => {
          this.locations = response.data;
        });
    },
    async getSubLocation(id) {
      let response = await this.$store.dispatch(
        "subLocation/getSubLocation",
        id
      );
      this.subLocation = response.data;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.nameState = null;
      this.subLocation = {
        name: "",
        location_id: null,
        location: null,
      };
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.subLocation.id) {
        this.updateSubLocation();
      } else {
        this.createSubLocation();
      }
    },
    createSubLocation() {
      this.subLocation.location_id = this.subLocation.location.id;
      this.$store
        .dispatch("subLocation/createSubLocation", this.subLocation)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.getSubLocations();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "SubLocation Create Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
    updateSubLocation() {
      this.$store
        .dispatch("subLocation/updateSubLocation", this.subLocation)
        .then((response) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.getSubLocations();
          toast(
            "Success",
            "CheckCircleIcon",
            "success",
            "SubLocation Update Successfully"
          );
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            // make error object to array
            const errors = Object.keys(error.response.data.error).map((key) => {
              return error.response.data.error[key];
            });
            toast(
              "Validation Error",
              "AlertTriangleIcon",
              "danger",
              errors.join(", ")
            );
          }
        });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}
.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
